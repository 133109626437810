<template>
  <div class="content" v-if="model">
    <div class="work-title">
      <block v-if="model.is_only_show == 0">{{ model.vote_code }}</block>
      {{ model.title }}
    </div>
    <div class="work-author">
      {{
        model.category_name +
        (model.parent_category_name ? " · " + model.parent_category_name : "")
      }}
    </div>
    <div class="work-author">{{ model.company }}</div>
    <div v-if="videoIds.length > 0">
      <div v-for="item in videoIds" :key="item">
        <div class="work-video-wrap">
          <img src="@/assets/images/shape.png" class="shape" />
          <img src="@/assets/images/video-box-top.png" class="video-box-top" />
          <div class="work-video-box">
            <div class="work-video">
              <div :id="'container' + item" class="video-wrapper">
                <div id="vidro-content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="work-intro" v-if="model.intro.length > 0">
      <div v-html="$decodeContent(model.intro)"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { worksDetail } from "../network/API";
import "../utils/txplayer.js";

const route = useRoute();

const model = ref(null);
const videoIds = ref([]);
const lastTap = ref(null);
const player = ref(null);

onMounted(() => {
  getWorksDetail(route.params.id);
});

async function getWorksDetail(vid) {
  var res = await worksDetail(vid);
  if (res.vid) {
    videoIds.value.push(res.vid);
    playerInit(res.vid);
  }
  if (res.vid2) {
    videoIds.value.push(res.vid2);
    playerInit(res.vid);
  }
  if (res.vid3) {
    videoIds.value.push(res.vid3);
    playerInit(res.vid);
  }
  model.value = res;
}
function playerInit(vid) {
  player.value = new Txplayer({
    containerId: "container" + vid,
    vid: vid,
    width: "345px",
    height: "178px",
    autoplay: false,
  });
}
</script>

<style scoped>
.container {
  justify-items: center;
}

::v-deep img {
  max-width: 100%;
}

.cover {
  width: 350px;
  height: 210px;
}

.content {
  padding: 15px;
  box-sizing: border-box;
}

.work-title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 700;
}

.work-author {
  color: #fff;
  font-size: 15px;
  margin-bottom: 15px;
}

.work-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin: 0;
  color: #fff;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-title {
  font-size: 15px;
  color: #fff;
  margin-bottom: 6px;
}

.item-value {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.work-intro {
  font-size: 15px;
  line-height: 1.8rem;
  color: #666;
  margin-bottom: 25px;
  padding: 15px;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  box-shadow: 0 15px 15px 0 rgba(69, 42, 124, 0.15);
  border-radius: 10px;
}

.work-video {
  background: #8848e0;
  padding: 4px;
  border-radius: 10px;
  border: 4px solid #a162f7;
}

.work-video-wrap {
  position: relative;
}
.shape {
  position: absolute;
  right: -15px;
  top: -50px;
}

.video-box-top {
  width: 687px;
  height: 26px;
  display: block;
  margin-left: 1px;
  margin-right: 1px;
  display: block;
}

.work-video-box {
  background-color: #caa3ff;
  padding: 8px;
  border-radius: 10px;
  margin-top: -10;
}

.work-video video {
  border-radius: 10px !important;
  overflow: hidden;
}

.player-index--container {
  background-color: transparent !important;
}

.work-fav-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  margin: 0 auto;
}

.fav-icon {
  width: 110px;
  height: 110px;
  justify-self: center;
  margin-bottom: 10px;
}

.fav-total {
  font-size: 18px;
  color: #fff;
}

/* .work-video {
  margin: 10px 0;
} */
</style>
